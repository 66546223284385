@config "../../tailwind.application.config.js";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html {
  height: 100%;
}

a[href],
input[type="checkbox"],
input[type="submit"],
input[type="image"],
input[type="radio"],
label[for],
select,
button {
  cursor: pointer;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.main {
  flex: 1;
}

.link {
  @apply text-emerald-600;

  &:hover,
  &:focus {
    @apply text-emerald-700;
  }
}

@layer components {
  .ui-form-input {
    @apply focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm border-gray-300 rounded-md;
  }

  .ui-btn {
    @apply inline-block px-6 py-3 text-center font-medium rounded-md text-white bg-emerald-600 focus:outline-none;
  }
}

@layer base {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  h1,
  .h1 {
    font-size: 2.5rem;

    @apply font-bold leading-tight tracking-tight;
  }

  h2,
  .h2 {
    font-size: 2rem;

    @apply font-bold leading-tight;
  }

  h3,
  .h3 {
    font-size: 1.5rem;

    @apply font-bold leading-normal;
  }

  h4,
  .h4 {
    font-size: 1.125rem;

    @apply font-bold leading-normal;
  }

  h5,
  .h5 {
    @apply font-bold uppercase tracking-wide text-sm;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#topnav.nav-sticky {
  @apply bg-white/80 backdrop-blur-md shadow;
}

@layer base {
  #topnav {
    @apply fixed right-0 left-0 top-0 z-50 bg-transparent border-0 transition-all duration-500 ease-in-out;
    .logo {
      @apply float-left text-black #{!important};
      .l-dark {
        @apply hidden;
      }
      .l-light {
        @apply inline-block;
      }
      &:focus {
        @apply outline-none;
      }
    }
    .has-submenu {
      @apply relative;
      &.active {
        a {
          @apply text-white;
        }
        .submenu {
          li {
            &.active {
              > a {
                @apply text-green-600 #{!important};
              }
            }
          }
        }
        &.active {
          .menu-arrow {
            @apply border-green-600;
          }
        }
      }
      .submenu {
        .submenu-arrow {
          border-width: 0px 0.125rem 0.125rem 0px;
          @apply absolute right-[1.25rem] top-[0.8125rem] border-black rounded-[0.5px] inline-block p-[2px] -rotate-[45deg];
        }
        .has-submenu {
          &:hover {
            > .submenu-arrow {
              @apply border-green-600;
            }
          }
          .submenu {
            .has-submenu {
              &:hover {
                .submenu-arrow {
                  @apply border-green-600;
                }
                > .submenu-arrow {
                  @apply border-green-600;
                }
              }
            }
          }
        }
      }
    }
    .navbar-toggle {
      @apply border-0 relative p-0 m-0 cursor-pointer;
      .lines {
        @apply w-[25px] block relative h-[18px] mt-[30px] mr-0 mb-[26px] ml-[10px];
      }
      span {
        transition: transform 0.5s ease;
        @apply h-[2px] w-full bg-black block mb-[5px];
        &:last-child {
          @apply mb-0;
        }
      }
      &.open {
        span {
          @apply absolute;
          &:first-child {
            @apply top-[6px] rotate-[45deg];
          }
          &:nth-child(2) {
            @apply invisible;
          }
          &:last-child {
            @apply w-full top-[6px] -rotate-[45deg];
          }
          &:hover {
            @apply bg-green-600;
          }
        }
      }
      &:hover,
      &:focus,
      .navigation-menu > li > a:hover {
        @apply bg-transparent;
      }
    }
    .buy-button {
      @apply float-right leading-[74px];
      > li {
        @apply leading-[initial];
      }
      .login-btn-primary,
      .btn-icon-dark {
        @apply hidden;
      }
      .login-btn-light,
      .btn-icon-light {
        @apply inline-block;
      }
      .search-bar {
        .menu-search {
          form {
            @apply relative;
          }
        }
        .searchform {
          @apply after:absolute after:right-[14px] after:top-[14px] after:text-xl after:leading-5 after:pointer-events-none #{!important};
          input[type="text"] {
            @apply shadow-none py-2.5 pr-[42px] pl-3 h-11 text-sm block outline-none #{!important};
          }
          input[type="submit"] {
            @apply hidden;
          }
          &:after {
            content: "\f0349";
            font-family: "Material Design Icons";
          }
        }
      }
    }
    .buy-button,
    .buy-menu-btn {
      .dropdown {
        .dropdown-toggle {
          @apply after:hidden;
        }
      }
    }
    .navigation-menu {
      @apply list-none m-0 p-0;
      > li {
        @apply float-left block relative my-0 mx-[10px];
        > a {
          @apply block text-black text-base bg-transparent font-medium tracking-[1px] leading-6 font-body px-[15px];
          &:hover,
          &:active {
            @apply text-green-600;
          }
        }
        &:hover,
        &.active {
          > a {
            @apply text-green-600 #{!important};
          }
        }
        .submenu.megamenu {
          li {
            .megamenu-head {
              @apply py-[10px] px-5 whitespace-nowrap text-xs uppercase tracking-[0.04em] font-semibold text-slate-400 #{!important};
            }
          }
        }
      }
      .has-submenu {
        .menu-arrow {
          border-width: 0 2px 2px 0;
          @apply border-black rounded-[0.5px] inline-block p-[2px] rotate-[45deg] absolute transition-all duration-500 right-0 top-8;
        }
      }
    }
    .menu-extras {
      @apply float-right;
    }
    &.scroll {
      @apply bg-white border-none shadow;
      .navigation-menu {
        > li {
          > a {
            @apply text-black;
          }
          > .menu-arrow {
            @apply border-black;
          }
          &:hover,
          &.active {
            > a {
              @apply text-green-600;
            }
            > .menu-arrow {
              @apply border-green-600;
            }
          }
        }
      }
    }
    &.defaultscroll {
      &.dark-menubar {
        .logo {
          @apply leading-[70px];
        }
      }
      &.scroll {
        .logo {
          @apply leading-[62px];
        }
      }
    }
    &.nav-sticky {
      .navigation-menu {
        &.nav-light {
          > li {
            > a {
              @apply text-black;
            }
            &.active {
              > a {
                @apply text-green-600 #{!important};
              }
            }
            &:hover,
            &.active {
              > .menu-arrow {
                @apply border-green-600 #{!important};
              }
            }

            &:hover,
            &.active {
              > a {
                @apply text-green-600 #{!important};
              }
            }
          }
          .has-submenu {
            .menu-arrow {
              @apply border-black;
            }
          }
        }
      }
      &.tagline-height {
        @apply top-0 #{!important};
      }
      .buy-button {
        .login-btn-primary,
        .btn-icon-dark {
          @apply inline-block;
        }
        .login-btn-light,
        .btn-icon-light {
          @apply hidden;
        }
      }

      .logo {
        .l-dark {
          @apply inline-block;
        }
        .l-light {
          @apply hidden;
        }
      }
    }
  }

  .logo {
    @apply font-semibold text-[24px] mr-[15px] pt-0 px-[6px] pb-0 tracking-[1px] leading-[68px];
  }

  @media (min-width: 1025px) {
    #topnav {
      .navigation-menu {
        > li {
          .submenu {
            &.megamenu {
              @apply w-[1116px] #{!important};
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) and (min-width: 992px) {
    #topnav {
      .navigation-menu {
        > li {
          .submenu {
            &.megamenu {
              @apply w-[936px] #{!important};
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    #topnav {
      .navigation-menu {
        @apply flex flex-wrap justify-center;
        > li {
          .submenu {
            transition: all 0.2s ease;
            @apply absolute top-full left-0 z-[1000] py-[15px] px-0 list-none min-w-[180px] invisible opacity-0 mt-[10px] rounded-md bg-white shadow;
            li {
              @apply relative;
              a {
                transition: all 0.3s;
                @apply block py-[10px] px-5 clear-both whitespace-nowrap text-[16px] tracking-[0.04em] font-normal text-black leading-[17px];
                &:hover {
                  @apply text-green-600 #{!important};
                }
              }
              ul {
                @apply list-none pl-0 m-0;
              }
            }
            &.megamenu {
              @apply whitespace-nowrap left-1/2 -translate-x-1/2 fixed top-auto flex;
              > li {
                @apply overflow-hidden align-top w-1/5;
                .submenu {
                  @apply left-full top-0 ml-[10px] -mt-px;
                }
              }
            }
            > li {
              .submenu {
                @apply left-[101%] top-0 ml-[10px] -mt-px;
              }
            }
          }
          > a {
            @apply py-[25px] min-h-[62px];
          }
          &:hover {
            > .menu-arrow {
              @apply border-green-600;
            }
          }
          &:hover,
          &.active {
            > a {
              @apply text-green-600 #{!important};
            }
          }
          &.last-elements {
            .submenu {
              @apply left-auto right-0 before:left-auto before:right-[10px];
              > li {
                &.has-submenu {
                  .submenu {
                    @apply left-auto right-full ml-0 mr-[10px];
                  }
                }
              }
            }
          }
        }
        &.nav-light {
          > li {
            > a {
              @apply text-white/50;
            }
            &.active {
              > a {
                @apply text-white #{!important};
              }
            }
            &:hover {
              > .menu-arrow {
                @apply border-white #{!important};
              }
              > a {
                @apply text-white #{!important};
              }
            }
          }
          .has-submenu {
            .menu-arrow {
              @apply border-white/50;
            }
            &.active {
              .menu-arrow {
                @apply border-white #{!important};
              }
            }
          }
        }
      }
      .buy-button {
        @apply pl-[15px] ml-[15px];
      }
      .navbar-toggle {
        @apply hidden;
      }
      #navigation {
        @apply block #{!important};
      }
      &.scroll {
        @apply top-0;
        .navigation-menu {
          > li {
            > a {
              @apply py-5;
            }
          }
        }
      }
      &.scroll-active {
        .navigation-menu {
          > li {
            > a {
              @apply py-[25px];
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    #topnav {
      @apply bg-white shadow min-h-[74px];
      .logo {
        .l-dark {
          @apply inline-block #{!important};
        }
        .l-light {
          @apply hidden #{!important};
        }
      }
      .container {
        @apply w-auto;
      }
      #navigation {
        @apply max-h-[400px] shadow;
      }
      .navigation-menu {
        @apply float-none;
        > li {
          @apply float-none;
          .submenu {
            @apply hidden list-none pl-5 m-0;
            li {
              a {
                transition: all 0.3s;
                @apply block relative py-[7px] px-[15px] tracking-[0.04em] text-black #{!important};
              }
            }
            &.megamenu {
              li {
                .megamenu-head {
                  @apply py-[7px] px-[15px];
                }
              }
              > li {
                > ul {
                  @apply list-none pl-0;
                  > li {
                    > span {
                      @apply block relative py-[10px] px-[15px] uppercase text-xs tracking-[2px] text-slate-200;
                    }
                  }
                }
              }
            }
            &.open {
              @apply block;
            }
            .submenu {
              @apply hidden list-none;
              &.open {
                @apply block;
              }
            }
          }
          > a {
            @apply text-black py-[10px] px-[20px] after:absolute after:right-[15px];
          }
          > a:hover,
          .submenu li a:hover,
          &.has-submenu.open > a {
            @apply text-green-600;
          }
        }
      }
      .menu-extras {
        .menu-item {
          @apply border-gray-200;
        }
      }
      .navbar-header {
        @apply float-left;
      }

      .buy-button {
        .login-btn-primary,
        .btn-icon-dark {
          @apply inline-block #{!important};
        }
        .login-btn-light,
        .btn-icon-light {
          @apply hidden;
        }
      }
      .has-submenu {
        .submenu {
          .submenu-arrow {
            @apply rotate-[45deg] absolute right-5 top-3;
          }
        }
        &.active {
          a {
            @apply text-green-600;
          }
        }
      }
    }

    #navigation {
      @apply absolute top-[74px] left-0 w-full hidden h-auto bg-white overflow-auto;
      &.open {
        @apply block overflow-y-auto;
      }
    }
  }

  @media (max-width: 768px) {
    #topnav {
      .navigation-menu {
        .has-submenu {
          .menu-arrow {
            @apply right-2 top-4;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    #topnav {
      .navigation-menu {
        > li {
          &.has-submenu {
            &:hover {
              > .submenu {
                @apply visible opacity-100 mt-0;
                > li {
                  &.has-submenu {
                    &:hover {
                      > .submenu {
                        @apply visible opacity-100 ml-0 mr-0;
                        > li {
                          &:hover {
                            > .submenu {
                              @apply visible opacity-100 ml-0 mr-0;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .navbar-toggle {
      @apply block;
    }
  }

  @media (max-width: 425px) {
    #topnav {
      .buy-menu-btn {
        @apply block my-0 mx-[10px] py-[10px] px-5 #{!important};
        .dropdown {
          .dropdown-menu {
            &.show {
              transform: translate3d(0px, -54px, 0px) !important;
            }
          }
        }
      }
    }
  }
  .tagline {
    @apply absolute w-full z-[99] text-sm py-[13px] px-0;
  }
  @media screen and (max-width: 575px) {
    .tagline {
      @apply hidden;
    }
    .tagline-height {
      @apply top-0 #{!important};
    }
  }
  @media (min-width: 576px) {
    .tagline-height {
      @apply top-[47px] #{!important};
    }
  }

  .sidebar-nav {
    @apply py-[15px] px-0;
    > .navbar-item {
      @apply py-[3px] px-[15px];
      .navbar-link {
        @apply text-[15px] font-semibold;
        .navbar-icon {
          @apply text-lg mr-[6px];
        }
      }
      &:hover,
      &.active {
        .navbar-link {
          @apply text-green-600;
        }
      }
    }
    li {
      &.active {
        a {
          @apply text-green-600;
        }
      }
      &.account-menu.active,
      &.account-menu:hover {
        .navbar-link {
          @apply bg-green-600 text-white #{!important};
        }
      }
    }
  }
}

@layer base {
  .btn {
    @apply focus:outline-none text-base font-medium h-10 px-7 rounded-lg inline-flex items-center justify-center align-middle;

    &.btn-lg {
      @apply h-14 px-7 text-lg;
    }
    &.btn-sm {
      @apply h-7 px-4 text-sm;
    }
    /*  Button icons */
    &.btn-icon {
      @apply p-0 h-10 w-10 inline-flex items-center text-center justify-center text-base;
      &.btn-lg {
        @apply h-14 w-14 text-lg;
      }
      &.btn-sm {
        @apply h-7 w-7;
      }
    }

    /* Button Link */
    &.btn-link {
      @apply relative h-7 p-0 border-none after:content-[''] after:absolute after:h-px after:w-0 after:right-0 after:bottom-0 after:left-0 after:transition-all after:duration-500;
      &:hover {
        @apply after:w-full after:right-auto;
      }
    }
  }
}

button .when-disabled {
  display: none;
}
button[disabled] .when-disabled {
  display: initial;
}
button .when-enabled {
  display: initial;
}
button[disabled] .when-enabled {
  display: none;
}
